<template lang="pug">
div.container
  div(v-if="!visitors.length > 0")
    loading-spinner(message="Loading visitors...")
  div(v-if="visitors.length > 0")
    b-row(align-h="between" align-v="center")
      h1 Manage visitors <router-link to="/events/new-visitor"><b-button variant="success"><fa-icon icon="plus" size="lg"></fa-icon></b-button></router-link>
      b-form(inline)
        b-input(type="text" placeholder="search" v-model="searchText" @click="resultLimit = 20")

    div.row
      table.table.table-bordered.table-hover.table-striped
        thead.thead-dark
          tr
            th Last Name
            th First Name
            th Company
            th Email
        tbody
          tr.table-row-link(v-for="visitor in displayedVisitors" :key="visitor._id" @click="$router.push('/events/visitors/' + visitor._id)")
            td {{visitor.lastName}}
            td {{visitor.firstName}}
            td {{visitor.organization}}
            td {{visitor.email}}

    div.row.justify-content-center.text-center
      p {{searchResults.length}} search results
        span(v-show="displayedVisitors.length < searchResults.length")  ({{displayedVisitors.length}} shown)
    div.row.justify-content-center
      b-button(v-show="displayedVisitors.length < searchResults.length" size="sm" variant="info" @click="loadMore()") Load more

</template>

<script>
export default {
  data: function () {
    return {
      resultLimit: 20,
      visitors: [],
      searchText: ''
    }
  },
  computed: {
    searchResults: function () {
      return this.visitors.filter(visitor => {
        if (this.searchText == null) return false // Avoid null refs...
        let s = this.searchText.toLowerCase()
        let hit = false

        // These could be cached but does it matter?
        let lastAndFirst = visitor.lastName + ' ' + visitor.firstName
        let firstAndLast = visitor.firstName + ' ' + visitor.lastName

        if (firstAndLast.toLowerCase().includes(s)) { hit = true } else if (lastAndFirst.toLowerCase().includes(s)) { hit = true } else if (visitor.hasOwnProperty('organization')) {
          if (visitor.organization.toLowerCase().includes(s)) { hit = true }
        } else if (visitor.hasOwnProperty('email')) {
          if (visitor.email.toLowerCase().trim().includes(s)) { hit = true }
        }

        return hit
      })
    },
    displayedVisitors: function () {
      return this.searchResults.slice(0, this.resultLimit)
    }
  },
  methods: {
    loadMore: function () {
      this.resultLimit += 20
    },
    sortByName: function (a, b) {
      if (!a.hasOwnProperty('lastName')) return 1
      if (!b.hasOwnProperty('lastName')) return -1
      let nameA = a.lastName.toLowerCase()
      let nameB = b.lastName.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }
  },
  mounted: function () {
    this.axios.get('/api/visitors')
      .then(res => {
        res.data.sort(this.sortByName)
        this.visitors = res.data
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching visitors failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>
